@use 'styles/energy-heading' as *;
@use 'styles/events-button' as *;

.Header {

    position: sticky;
    [data-site='events'] & ,
    [data-site='magazines'] & {
        position: relative;
    }

    top: 0;
    z-index: var(--layer-menu);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 100vw;
    overflow: hidden;
    [data-site='events'] & ,
    [data-site='magazines'] & {
        flex-direction: row;
        width: 100%;
        @media ( max-width: 1000px ) {
            flex-direction: column;
        }
    }
    [data-site='events'] & {
        overflow: visible;
    }

    > button {

        @media ( min-width: 0px ) {
           display: block;
           right: 20px;
        }
        [data-site='main'] &,
        [data-site='magazines'] & {
            @media ( min-width: 525px ) and ( min-height: 525px ) {
                display: none;
            }
        }

        [data-site='events'] & ,
        [data-site='magazines'] & {
            width: var(--spacing-small);
            height: var(--spacing-small);
            svg {
                width: var(--spacing-small);
                height: var(--spacing-small);
                fill: var(--colour-background-accent-secondary);
            }
        }

        [data-site='magaines'] & {
            @media ( min-width: 0px ) {
                display: block !important;
            }
            @media ( min-width: 525px ) {
                display: none !important;
            }
            @media ( max-height: 525px) {
                display: block !important;
            }
        }

        [data-site='events'] & {
            @media ( min-width: 0px ) {
                display: block !important;
            }
            @media ( min-width: 650px ) {
                display: none !important;
            }
            @media ( max-height: 650px) {
                display: block !important;
            }
        }

        position: absolute;
        z-index: var(--layer-menu-controls);
        right: var(--page-padding-horizontal);
        top: 20px;
        padding: 0;
        border: none;
        background: transparent;
        cursor: pointer;

        svg {
            margin: 0;
            padding: 0;
            width: 1em;
            height: 1em;
            path {
                transition: all 0.35s ease-in-out;
                stroke: var(--colour-text-accent-primary);
            }
        }

    }

    &[data-display-state='Search'] {
        height: 100vh;
        min-height: 100vh;
        max-height: 100vh;
        nav { display: none; }
        & ~ * { display: none; }
        @media( min-width: 525px ) {
            > .Menu > :global(.ais-SearchBox),
            > .Nav {
                margin-right: calc( var(--page-padding-horizontal) + 15px );
            }
        }
    }

    [data-site='events'] & {
        padding: 10px 0;
        display: flex;
        flex-direction: column-reverse;
        gap: var(--spacing-small-half);
        background: var(--colour-background-primary);
    }

}

.Menu {

    display: grid;
    [data-site='magazines'] & {
        display: none !important;
    }

    padding: 0;
    min-height: 46px;

    > section {
        grid-area: links;
    }
    > :global(.ais-SearchBox) {
        grid-area: searchbox;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        [data-site='events'] & { display: none; }
    }
    > div:last-child { grid-area: results; }

    @media ( min-width: 0px ) {
        grid-template-areas:
            "links"
            "searchbox"
            "results";
        [data-site='events'] & {
            grid-template-areas: "links";
        }
        [data-display-state='Minimal'] & {
            display: none;
        }
        [data-display-state='Navigation'] &
        [data-display-state='Search'] & {
            display: grid;
        }
        > section,
        > :global(.ais-SearchBox) {
            padding:
                0
                calc( var(--page-padding-horizontal) + var(--spacing-small) + 18px )
                0
                var(--page-padding-horizontal);
        }
    }
    @media ( max-height: 525px ) {
        [data-display-state='Navigation'] &,
        [data-display-state='Search'] & {
            padding-bottom: 16px;
        }
    }
    [data-site='main'] & {
        @media ( min-width: 525px ) and ( min-height: 525px ) {
            display: grid !important;
            grid-template-areas:
                "links searchbox"
                "results results";
            grid-template-columns: 1fr 270px;
            [data-site='events'] & {
                grid-template-areas: "links";
                grid-template-columns: 100%;
            }
            padding: 0 var(--page-padding-horizontal);
            [data-display-state='Search'] & {
                padding: 0;
                grid-template-columns: 1fr min-content;
            }
            > section,
            > :global(.ais-SearchBox) {
                padding: 0;
            }
        }
    }

    [data-site='events'] & {
        > section { display: contents; }
        @media ( min-width: 650px ) {
            display: grid !important;
            grid-template-areas: "links";
            grid-template-columns: 100%;
            padding: 0 var(--page-padding-horizontal);
        }
    }


    [data-display-state='Search'] & {
        padding-bottom: 0;
    }

    position: relative;
    z-index: var(--layer-submenu);
    margin: 0;
    width: 100%;

    grid-gap: 0 var(--spacing-medium-three-quarters);

    > section {
        display: flex;
        flex-direction: row;
    }

    @media ( min-width: 0px ) {
        padding-bottom: 16px;
        > section {
            width: fit-content;
            [data-site='events'] & { width: 100%; }
            max-width: 100vw;
            justify-content: stretch;
            ul {
                width: 100%;
                flex-wrap: wrap;
                align-items: center;
                gap: 0 var(--spacing-small);
                padding-top: 6px;
                padding-bottom: 6px;
                [data-site='events'] & {
                    justify-content: space-between;
                    @media ( min-width: 650px) and ( max-width: 1260px ) {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-template-rows: min-content min-content;
                    }
                }
                > li {
                    padding-top: 0;
                    padding-bottom: 0;
                    transform: translateY(2px);
                }
            }
        }
    }
    @media ( min-width: 850px ) {
        padding-bottom: 0;
        > section {
            width: fit-content;
            justify-self: flex-end;
            justify-content: flex-end;
            ul { width: nin-content; }
        }
    }

    color: var(--colour-text-tertiary);
    background: var(--colour-background-accent-primary);

    strong {
        color: var(--colour-text-secondary);
        font-weight: normal;
    }

    ul {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: var(--spacing-medium-three-quarters);
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
        padding: 6px 0;
    }

    a,
    button {
        color: var(--colour-text-accent-primary);
        text-transform: uppercase;
        font-variant: small-caps;
        font-size: var(--font-size-tiny);
        &:hover {
            color: var(--colour-text-secondary) !important;
        }
    }

    button {
        border: 0;
        padding: 0;
        background: transparent;
    }

    [data-site='events'] & {
        > section {
            > ul {
                @media (max-width: 650px) {
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: var(--page-padding-horizontal) !important;
                    padding-right: var(--page-padding-horizontal) !important;
                }
                > li {
                    * { font-size: var(--font-size-large) !important; }
                    > button {
                        text-transform: none;
                        font-feature-settings: normal;
                        font-variant: normal;
                        cursor: pointer;
                    }
                    > button,
                    > a {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0.25em;
                        height: 58px;
                        width: max-content;
                        padding: var(--spacing-small-half) !important;
                        background: var(--colour-background-primary);
                        border-top-left-radius: var(--spacing-small-half);
                        border-top-right-radius: var(--spacing-small-half);
                        @media (max-width: 650px) {
                            margin-left: calc( -1 * var(--spacing-small-half) ) !important;
                            height: 45px;
                        }
                    }
                }
            }
        }
        a {
            text-transform: none;
            font-variant: normal;
        }
    }

}

.Nav {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    [data-site='main'] &,
    [data-site='data'] & {

        @media ( min-width: 0px ) {
            padding:
                0
                calc( var(--page-padding-horizontal) + var(--spacing-small) + 18px )
                0
                var(--page-padding-horizontal);
            gap: var(--spacing-small);
            > button {
                display: block;
                margin: auto;
            }
        }
        @media ( min-width: 525px ) and ( min-height: 525px )  {
            padding: 0 var(--page-padding-horizontal);
            gap: var(--spacing-medium);
            > a { margin-top: 0; }
            > button { display: none; }
        }

    }

    [data-site='events'] & ,
    [data-site='magazines'] & {

        width: 100%;
        padding-left: var(--page-padding-horizontal);
        padding-right: var(--page-padding-horizontal);
        gap: 0 var(--spacing-medium);
        @media ( max-width: 750px ) {
            flex-direction: column;
        }
        [data-site='events'] & {
            @media ( max-width: 850px ) {
                flex-direction: column;
            }
        }
    }

    // [data-site='magazines'] & {
    //     width: fit-content;
    //     max-width: 100vw;
    //     @media ( max-width: 1000px ) {
    //         width: 100%;
    //     }
    // }

    margin: 0;
    min-height: 60px;

    background: var(--colour-background-primary);
    border-top: 1px solid hsl(0 0% 20%);
    border-bottom: 1px solid hsl(0 0% 20%);
    filter: var(--filter-shadow-mid);
    [data-site='events'] & ,
    [data-site='magazines'] & {
        border-top: none;
        border-bottom: none;
        filter: none;
    }


    > section {
        flex-grow: 1;
    }

    [data-site='events'] & {
        justify-content: space-between;
        h1 {
            font-size: var(--font-size-header);
            white-space: nowrap;
            @media ( max-width: 350px ) {
                white-space: normal;
            }
            text-transform: none !important;
            border: none !important;
            padding-top: 0.5em;
            margin-top: 0.5em;
            margin-bottom: 0;
            &::before {
                top: auto;
                bottom: 100%;
                left: 0;
                right: 0;
            }
        }
    }
}

.Nav > section {

    // [data-site='magazines'] & {
    //     display: none;
    // }

    flex-grow: 1;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-medium);
    [data-site='events'] & {
        flex-direction: column;
        gap: var(--spacing-small);
        flex-grow: 0;
        align-items: center;
        @media ( min-width: 1100px ) {
            display: grid;
            grid-template-areas: 
            "blank text"
            "link1 link2";
            grid-template-columns: 1fr max-content;
            gap: var(--spacing-small);
            > ul > li > section {
                grid-area: text;
            }
            > ul > li > a {
                grid-area: link1;
            }
            > ul > li:last-of-type > a {
                grid-area: link2;
            }
        }
        @media ( min-width: 850px ) {
            align-items: flex-end;
        }
        @media ( max-width: 650px ) {
            gap: var(--spacing-small-half);
        }
        justify-content: center;
    }
    [data-display-state='Navigation'] & {
        [data-site='events'] & {
            margin-top: var(--spacing-small);
            > ul > li > section {
                display: none !important;
            }
        }
    }

    //@media ( max-width: 1050px ) { display: none; }

    > picture, > img, > button { flex-grow: 0; }

    > ul {
        flex-grow: 1;
        display: grid;
        [data-site='magazines'] & {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            height: min-content;
            justify-content: space-evenly;
            align-self: flex-end;
            gap: var(--spacing-small-half) var(--spacing-small);
            @media ( max-width: 525px ) {
                flex-direction: column;
                padding-bottom: var(--spacing-small-half);
            }
            > li > a {
                padding: 0;
                margin: 0;
                height: auto;
            }
        }
        > li { display: contents; }
        @media ( min-width: 0px ) {
            grid-template-columns: 1fr;
            > li > * {
                display: none;
                [data-site='events'] & { display: flex; }
                &[data-prefix='true'] {
                    display: flex;
                    [data-site='magazines'] & {
                        @media ( max-width: 525px ) {
                            display: none;
                        }
                    }
                }

            }
            [data-display-state='Navigation'] & {
                > li > * {
                    display: flex !important;
                }
            }
        }
        [data-site='events'] & {
            > li > * {
                flex-direction: column;
                text-align: right;
                align-items: center;
                > h1 { color: var(--colour-background-accent-tertiary); }
                > h2 { color: var(--colour-background-accent-secondary); }
            }
        }
        @media ( min-height: 525px ) {
            @media ( min-width: 525px ) {
                padding-top: var(--spacing-small-half);
                padding-bottom: var(--spacing-small-half);
                grid-template-columns: repeat(2,min-content);
                gap: 0 var(--spacing-medium-three-quarters);
                > li > * {
                    display: flex;
                    [data-site='events'] & {
                        align-items: flex-end;
                        h1 {
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }
                        h2 {
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
        @media ( max-height: 524px ) {
            [data-display-state='Navigation'] &,
            [data-display-stata='Search'] & {
                padding-top: var(--spacing-small-half);
                padding-bottom: var(--spacing-small-half);
                grid-template-columns: repeat(2,min-content);
                gap: 0 var(--spacing-medium) !important;
                gap: 0 var(--spacing-medium-three-quarters);
                > li > * { display: flex; }
            }
        }
        @media ( min-width: 735px ) {
            grid-template-columns: repeat(3,min-content);
            gap: 0 var(--spacing-medium-three-quarters);
        }
        @media ( min-width: 780px ) {
            gap: 0 var(--spacing-medium);
        }
        @media ( min-width: 850px ) {
            gap: 0 var(--spacing-large);
        }
        @media ( min-width: 950px ) {
            gap: 0 calc( 2 * var(--spacing-medium) );
        }
        @media ( min-width: 1170px ) {
            grid-template-columns: repeat(6,min-content);
            gap: 0 var(--spacing-medium-three-quarters);
        }
        @media ( min-width: 1275px ) {
            gap: 0 var(--spacing-medium);
        }
        @media ( min-width: 1400px ) {
            gap: 0 var(--spacing-large);
        }
        align-items: stretch;
        [data-site='events'] & {
            display: contents;
        }
        justify-content: flex-end;
        align-self: stretch;
        padding: 0;
        margin: 0;
        > li {
            > a {
                width: 100%;
                flex-direction: column;
                justify-content: center;
                [data-site='magazines'] & {
                    justify-content: flex-end;
                    width: fit-content;
                }
                line-height: 24px;
                padding-top: 8px;
                padding-bottom: 4px;
                margin-bottom: 8px;
                border-bottom: 3px solid transparent;
                color: var(--colour-text-accent-secondary);
                [data-site='magazines'] & {
                    color: var(--colour-text-accent-primary);
                }
                //color: hsl(0,0%,20%);

                @media ( min-width: 0px ) {
                    white-space: normal;
                }
                @media ( min-width: 850px ) {
                    white-space: nowrap;
                }

                text-align: left;
                font-size: var(--font-size-large);
                &:hover {
                    text-decoration: none;
                    color: var(--colour-text-accent-primary);
                }
                &[data-prefix='true'] {
                    border-bottom: 3px solid var(--colour-text-accent-primary);
                }

                [data-site='events'] & {
                    font-size: var(--font-size-header) !important;
                    flex-grow: 0 !important;
                    border: 1px solid var(--colour-link);
                    @include events-button;
                    color: var(--colour-link);
                    margin-top: 0;
                    border-radius: var(--spacing-medium);
                }
                [data-site='textiles-recycling-expo'] & {
                    color: white;
                }
            }
        }
        > li:nth-child(even) {
            > a {
                [data-site='events'] & {
                    background: var(--colour-background-accent-tertiary);
                }
            }
        }
        > li:nth-child(odd) {
            > a {
                [data-site='events'] & {
                    background: var(--colour-background-accent-secondary);
                }
            }
        }
    }

}

.Nav > a {
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    [data-site='events'] & ,
    [data-site='magazines'] & {
        max-width: calc( 100vw - 2 * var(--page-padding-horizontal) );
        > span {
            max-width: calc(100vw - 2*var(--page-padding-horizontal)) !important;
        }
        @media ( max-width: 525px ) {
            margin-top: var(--spacing-medium);
            margin-bottom: var(--spacing-small-half);
        }
    }
}
